import React, { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { BsChevronDown } from 'react-icons/bs';
import { BiShow, BiHide } from 'react-icons/bi';
import axios from 'axios';
import { toast } from 'react-toastify';

const languageOptions = [
    {
        id: "dk",
        name: "Danish",
        flagimg: "https://i.ibb.co/8j1n195/dk.jpg",
    },
    {
        id: "en",
        name: "English",
        flagimg: "https://i.ibb.co/D9bJRhQ/eng.png",
    },
    {
        id: "gr",
        name: "German",
        flagimg: "https://i.ibb.co/DYs98MX/german.png",
    },
    {
        id: "nl",
        name: "Netherland",
        flagimg: "https://i.ibb.co/bKcfpGJ/natherland.jpg",
    },
];

const defaultLangFlag = <img src={languageOptions[1].flagimg} height="30" width="30" alt="nope" />;

const LoginPage = () => {
    const [cssDisplay, setCssDisplay] = useState('none');
    const [langFlag, setLangFlag] = useState(defaultLangFlag)
    const navigate = useNavigate();
    const [show, setShow] = useState(false);

    useEffect(() => {
        const token = JSON.parse(localStorage.getItem('token'));
        if (token) {
            navigate("/settings");
        };
    }, []);

    const showDropdown = () => {
        if (cssDisplay === 'none') {
            setCssDisplay('block');
        } else {
            setCssDisplay('none');
        }
    };

    const selectListItem = (event) => {
        // handleLanguageChange(event);
        setCssDisplay('none');
        setLangFlag(<img src={event.target.src} className='w-8' alt="nope" />);
    };

    const handleLogin = (e) => {
        e.preventDefault();

        const email = e.target.email.value;
        const password = e.target.password.value;

        const userData = {
            login: email,
            pass: password,
            app: "rackbeat",
        };

        const options = {
            method: 'POST',
            url: 'https://engine.netsupport.dk:7080/account/v1/retrieverackbeattoken',
            headers: { accept: 'application/json', 'content-type': 'application/json' },
            data: userData
        };

        axios
            .request(options)
            .then(function (response) {
                localStorage.setItem('token', JSON.stringify(response.data.token));
                navigate("/settings");
                toast.success("Successfully sign in!");
            })
            .catch(function (error) {
                console.error(error);
            });
    };

    const handleShow = () => {
        setShow(!show);
    };

    return (
        <div className='flex bg-slate-100'>
            <div className='hidden lg:flex w-4/6 h-screen justify-center items-center relative'>
                <img className='w-52 absolute top-10 left-10' src="https://integreat.dk/wp-content/uploads/2022/11/InteGreat-logo.png" alt="logo" />
                <div className='w-4/6'>
                    <img src='https://ig.integreat.dk/img/forIG5_light.39848a03.png' />
                </div>
            </div>
            <div className='flex justify-center items-center mx-auto h-screen px-16 bg-white w-full lg:w-2/6'>
                <div className='w-full max-w-md lg:max-w-sm'>
                    <h2 className='text-3xl text-gray-600'>Welcome to<br />InteGreat for Rackbeat!</h2>

                    <div className='mt-3 relative'>
                        <button onClick={showDropdown} className='flex items-center gap-3'>{langFlag} <BsChevronDown /></button>

                        <ul style={{ display: cssDisplay }} className='bg-white shadow-lg rounded-md p-3 pb-1 w-fit absolute left-8'>
                            {languageOptions.map((lang) => (
                                <li id={lang.id} key={lang.id} disabled className='flex z-50 gap-2 mb-2'>
                                    <img onClick={selectListItem} src={lang.flagimg} height="30" width="30" alt="flagpic" id={lang.id} />
                                    {lang.name}
                                </li>
                            ))}
                        </ul>
                    </div>

                    <p className='my-5 text-lg'>Please sign-in to your account</p>

                    <form onSubmit={handleLogin} className='flex flex-col gap-4'>
                        <div>
                            <p className='text-sm mb-1'>Email or Username</p>
                            <input name='email' required type="email" placeholder="john@example.com" className={`input border-slate-300 w-full rounded-md focus:outline-0 focus:border-gray-600 dark:text-gray-800`} />
                        </div>

                        <div className='relative h-12'>
                            <input name='password' required type={show === true ? "text" : "password"} placeholder="............" className="input border-slate-300 w-full rounded-md focus:outline-0 focus:border-gray-600 absolute dark:text-gray-800" />
                            <p className='absolute right-3 top-3 cursor-pointer' onClick={handleShow}>
                                {
                                    show === false ? <BiShow size={22} /> : <BiHide size={22} />
                                }
                            </p>
                        </div>

                        <button type='sumbit' className='btn bg-gray-600 text-white hover:bg-gray-700 w-full'>Sign in</button>
                    </form>

                    <p className='text-xl font-medium text-slate-600 mt-6 text-center py-0'>New on our platform? <Link to='/registration' className='btn bg-gray-600 text-white hover:bg-gray-700 py-0 px-6'>create</Link></p>
                </div>
            </div>
        </div>
    );
};

export default LoginPage;