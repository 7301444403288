import { createBrowserRouter } from "react-router-dom";
import Main from "../layout/Main";
import LoginPage from "../pages/LoginPage";
import RegistrationPage from "../pages/RegistrationPage";
import Settings from "../pages/Settings";

export const router = createBrowserRouter([
    {
        path: '/',
        element: <Main />,
        children: [
            {
                path: '/',
                element: <LoginPage />
            },
            {
                path: '/login',
                element: <LoginPage />
            },
            {
                path: '/registration',
                element: <RegistrationPage />
            },
            {
                path: '/settings',
                element: <Settings />
            }
        ],
    },
]);