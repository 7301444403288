import React, { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import axios from 'axios';
import "react-responsive-modal/styles.css";
import { Modal } from "react-responsive-modal";
import video from '../media/InteGreatforRackbeat.mp4';
import { toast } from 'react-toastify';
import { styles } from '../styles/styles';

const Settings = () => {
    const [token, setToken] = useState("");
    const [menu, setMenu] = useState([]);
    const [dynamicNav, setDynamicNav] = useState("");
    const [render, setRender] = useState(true);
    const [autoCall, setAutoCall] = useState(true);
    const [optionsFromMenu, setOptionsFromMenu] = useState([]);
    const navigate = useNavigate();
    const [open, setOpen] = useState(false);
    const onOpenModal = () => setOpen(true);
    const onCloseModal = () => setOpen(false);
    const [isChecked, setChecked] = useState(true);
    const [formData, setFormData] = useState({});
    const [optionsEnable, setOptionsEnable] = useState({});
    let idx_enable = 0;

    const handleChange = (event) => {
        const { name, value } = event.target;
        setFormData((prevFormData) => ({ ...prevFormData, [name]: value }));
    };

    useEffect(() => {
        const token = JSON.parse(localStorage.getItem('token'));
        if (token) {
            setToken(token);
        } else {
            navigate("/login");
        }
    }, [navigate]);

    const options = {
        method: 'GET',
        url: `https://engine.netsupport.dk:7080/account/v2/rackbeatmenu/${token}?app=rackbeat&menu=leftMenu`,
        headers: { accept: 'application/json' }
    };

    render && token && axios
        .request(options)
        .then(function (response) {
            setMenu(response.data.menu);
            setDynamicNav(response.data.menu[0].name);
            setRender(false);
        })
        .catch(function (error) {
            localStorage.removeItem("token");
            navigate("/login");
        });

    const handleDymanicNav = (value) => {
        setDynamicNav(value);

        const getOptions = {
            method: 'GET',
            url: `https://engine.netsupport.dk:7080/account/v2/rackbeatoptions/${token}?app=rackbeat&menu=leftMenu&name=${value}`,
            headers: { accept: 'application/json' }
        };

        axios
            .request(getOptions)
            .then(function (response) {
                setOptionsFromMenu(response.data.options);
                setFormData({});
                setChecked(true);
            })
            .catch(function (error) {
                console.error(error);
            });
    };

    const fetchOptionsEnable = async () => {
        try {
            const optionEnable = {
                method: 'GET',
                url: `https://engine.netsupport.dk:7080/rackbeat/v2/rackbeatoptionsenabled/${token}`,
                headers: { accept: 'application/json' }
            };

            const response = await axios.request(optionEnable);
            setOptionsEnable(response.data);
        } catch (error) {
            console.error(error);
        }
    };

    useEffect(() => {
        fetchOptionsEnable();
    }, [menu, token]);

    // useEffect(() => {
    //     const optionEnable = {
    //         method: 'GET',
    //         url: `https://engine.netsupport.dk:7080/rackbeat/v2/rackbeatoptionsenabled/${token}`,
    //         headers: { accept: 'application/json' }
    //     };

    //     if (menu && token) {
    //         axios
    //             .request(optionEnable)
    //             .then(function (response) {
    //                 setOptionsEnable(response.data);
    //             })
    //             .catch(function (error) {
    //                 console.error(error);
    //             });
    //     }
    // }, [menu, token]);


    if (autoCall === true && dynamicNav) {
        handleDymanicNav(dynamicNav);
        setAutoCall(false);
    };

    const handleCheck = (e) => {
        setChecked(!isChecked);
    };

    const handleSendConfig = (e) => {
        e.preventDefault();

        const newData = {
            save_options: [
                {
                    options: {
                        ...formData,
                        // [`${dynamicNav.split(' ')[0].toLowerCase()}_enabled`]: !isChecked ? 'y' : 'n'
                        [`${dynamicNav.split(' ')[0].toLowerCase()}_enabled`]: 'y'
                    },
                    group: dynamicNav
                }
            ]
        }

        const options = {
            method: 'POST',
            url: `https://engine.netsupport.dk:7080/rackbeat/v1/rackbeatconfiguration/${token}`,
            headers: { accept: 'application/json', 'content-type': 'application/json' },
            data: newData
        };

        axios
            .request(options)
            .then(function (response) {
                toast.success("Configuration saved!")
            })
            .catch(function (error) {
                console.error(error);
            });
    };

    const isEnable = (value, check) => {
        const temp = value.toLowerCase();
        return temp.includes(check);
    };

    return (
        <div>
            <div className='border-0 bg-white'>
                <div className="navbar mx-auto border-b-[1px] border-b-slate-300 p-6 mb-4">
                    <div className="flex-1">
                        <Link to='/'>
                            <img className='w-32' src="https://integreat.dk/wp-content/uploads/2022/11/InteGreat-logo.png" alt="logo" />
                        </Link>

                        <div>
                            <h2 className='text-lg font-medium ml-16 text-gray-700'>{dynamicNav}</h2>
                        </div>
                    </div>
                    <div className="flex-none">
                        {/* <div className='hidden lg:flex'>
                            <ul className="text-md btn btn-link no-underline hover:no-underline cursor-default flex gap-9 font-medium">
                                <li className='hover:text-amber-600'><Link to='/page1'>Hvad er InteGreat</Link></li>
                                <li className='hover:text-amber-600'><Link to='/page2'>Sådan virker det</Link></li>
                                <li className='hover:text-amber-600'><Link to='/page3'>Kundeportal</Link></li>
                                <li className='hover:text-amber-600'><Link to='/page4'>Priser</Link></li>
                                <li className='hover:text-amber-600'><Link to='/page5'>Kontakt</Link></li>
                            </ul>
                        </div> */}
                        {/* <div className="dropdown dropdown-end">
                            <label tabIndex={0} className="btn btn-link no-underline hover:no-underline font-medium text-md hover:text-amber-600">Login</label>
                            <ul tabIndex={0} className="menu dropdown-content z-[1] p-2 shadow bg-base-100 rounded-box w-52 mt-4">
                                <li><a>Item 1</a></li>
                                <li><a>Item 2</a></li>
                            </ul>
                        </div> */}
                    </div>
                </div>
            </div>
            <div className='flex gap-4 px-6'>
                <div className='w-80 rounded-md'>
                    {
                        <ul className='text-base w-full rounded-md '>
                            {
                                menu.map((item, idx) =>
                                    <li className={`${dynamicNav === item.name && "bg-[#2D838E]"} border-b-[1px] border-b-[#2D838E] py-2 px-2 flex justify-between items-center cursor-pointer w-full rounded-md`} onClick={() => handleDymanicNav(item.name)} key={idx}>
                                        <div className='flex gap-2 w-full py-1'>
                                            <span className="">
                                                {
                                                    item.state === "red" ? "❌" : item.state === "yellow" ? "⚠️" : item.state === "green" ? "✅" : "➕"
                                                }
                                            </span>
                                            {
                                                optionsEnable?.options_enabled?.[idx_enable]?.app === item.name.toLowerCase() ? (
                                                    <div className={`flex gap-2 items-center justify-between w-full`}>
                                                        <p>{item.name}</p>
                                                        <span
                                                            style={{ backgroundColor: optionsEnable?.options_enabled?.[idx_enable]?.state }}
                                                            className="h-4 w-4 rounded-full"
                                                        ></span>
                                                        <span className="hidden">{(idx_enable += 1)}</span>
                                                    </div>
                                                ) : (
                                                    <div>
                                                        <p>{item.name}</p>
                                                    </div>
                                                )
                                            }

                                        </div>
                                    </li>
                                )
                            }
                        </ul>
                    }
                </div>
                <div className='bg-slate-200 dark:bg-[#283046] w-full min-h-full rounded-md p-2 flex justify-center pt-4'>
                    <div className='max-w-3xl w-full lg:w-3/4 xl:w-1/2'>
                        <div className='flex justify-between items-center mt-4'>
                            <div className='flex gap-2 items-center dark:text-white'>
                                <input className='w-4 h-4' name='enable' id='enable' checked type="checkbox" onChange={handleCheck} />
                                <label htmlFor="enable">{dynamicNav} Enabled</label>
                            </div>
                            {
                                (dynamicNav === "Rackbeat" || dynamicNav === "rackbeat") &&
                                <div className='flex justify-end items-center'>
                                    <Modal open={open} onClose={onCloseModal} center>
                                        <video controls autoPlay>
                                            <source src={video} />
                                            Your browser does not support the video tag.
                                        </video>
                                    </Modal>
                                    <button
                                        onClick={onOpenModal}
                                        className={`${styles.button}`}
                                    >
                                        info
                                    </button>
                                </div>
                            }
                        </div>
                        <form onSubmit={handleSendConfig} className='mt-6 flex flex-col gap-2'>
                            <ul className='flex flex-col gap-3'>
                                {
                                    optionsFromMenu.length === 0 ?
                                        <p className='border-b-slate-300 border-b-[1px]'>Empty options</p>
                                        :
                                        optionsFromMenu.map((options, idx) => <div key={idx}>
                                            {
                                                isEnable(options.question, 'enable') ? <></> : <>
                                                    {
                                                        (idx === 0 && (dynamicNav === "Rackbeat" || dynamicNav === 'rackbeat')) ?
                                                            <Textarea key={idx} id={idx} label={options.question} value={formData[options.field] ? formData[options.field] : ''} name={options.field} onChange={handleChange} />
                                                            :
                                                            <Input key={idx} id={idx} label={options.question} value={formData[options.field] ? formData[options.field] : ''} name={options.field} onChange={handleChange} />
                                                    }
                                                </>
                                            }
                                        </div>)
                                }
                                {
                                    optionsFromMenu.length !== 0 && <button type='submit' className={`${styles.button}`}>save</button>
                                }
                            </ul>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Settings;

const Input = (props) => {
    return (
        <div>
            <label> {props.label}
                <input type="text" required {...props}
                    className={`input ${styles.input}`} />
            </label>
        </div>
    );
};

const Textarea = (props) => {
    return (
        <div>
            <label className=''>{props.label}
                <textarea required {...props}
                    className={`textarea ${styles.input} w-full h-40`} />
            </label>
        </div>
    );
};