import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { AiOutlineArrowRight } from 'react-icons/ai'
import { BsChevronDown } from 'react-icons/bs';
import axios from 'axios';
import { toast } from 'react-toastify';

const languageOptions = [
    {
        id: "dk",
        name: "Danish",
        flagimg: "https://i.ibb.co/8j1n195/dk.jpg",
    },
    {
        id: "en",
        name: "English",
        flagimg: "https://i.ibb.co/D9bJRhQ/eng.png",
    },
    {
        id: "gr",
        name: "German",
        flagimg: "https://i.ibb.co/DYs98MX/german.png",
    },
    {
        id: "nl",
        name: "Netherland",
        flagimg: "https://i.ibb.co/bKcfpGJ/natherland.jpg",
    },
];

const defaultLangFlag = <img src={languageOptions[1].flagimg} height="30" width="30" alt="nope" />;

const RegistrationPage = () => {
    const [cssDisplay, setCssDisplay] = useState('none');
    const [langFlag, setLangFlag] = useState(defaultLangFlag)
    const [cvr, setCvr] = useState("");
    const [company, setCompany] = useState("");
    const [companyAddress, setCompanyAddress] = useState("");
    const [zip, setZip] = useState("");
    const [city, setCity] = useState("");
    const navigate = useNavigate();

    const showDropdown = () => {
        if (cssDisplay === 'none') {
            setCssDisplay('block');
        } else {
            setCssDisplay('none');
        }
    };

    const selectListItem = (event) => {
        // handleLanguageChange(event);
        setCssDisplay('none');
        setLangFlag(<img src={event.target.src} className='w-8' alt="nope" />);
    };


    const changeCvr = (e) => {
        if (e.target.value.length === 8) {
            const options = {
                method: 'GET',
                url: `https://engine.netsupport.dk:7080/account/v1/getcvrinfo?cvr=${e.target.value}`,
                headers: { accept: 'application/json' }
            };

            axios
                .request(options)
                .then(function (response) {
                    // console.log(response.data.cvrdata);
                    const data = response.data.cvrdata;
                    setCvr(data.vat);
                    setCompany(data.name);
                    setCompanyAddress(data.address);
                    setZip(data.zipcode);
                    setCity(data.city);
                })
                .catch(function (error) {
                    console.error(error);
                    toast.error("Type the correct cvr number!")
                });
        } else {
            setCompany("");
            setCompanyAddress("");
            setZip("");
            setCity("");
            setCvr("");
        }
    }

    const handleRegistration = (e) => {
        e.preventDefault();

        const name = e.target.name.value;
        const email = e.target.email.value;
        const phone = e.target.phone.value;

        const userData = {
            name: name,
            email: email,
            mobile: phone,
            cvr: cvr ? cvr : "",
            company: company,
        }

        const options = {
            method: 'POST',
            url: 'https://engine.netsupport.dk:7080/account/v1/createrackbeatlogin',
            headers: { accept: 'application/json', 'content-type': 'application/json' },
            data: userData
        };

        axios
            .request(options)
            .then(function (response) {
                navigate('/login');
                toast.success("Registered successfully! Now, check your email for login email and password.");
            })
            .catch(function (error) {
                console.error(error);
            });
    };

    return (
        <div className='flex items-center bg-slate-100 relative'>
            <div className='hidden lg:flex w-4/6 justify-center items-center h-screen'>
                <img className='w-52 absolute top-10 left-10' src="https://integreat.dk/wp-content/uploads/2022/11/InteGreat-logo.png" alt="logo" />
                <div className='w-4/6'>
                    <img src='https://ig.integreat.dk/img/forIG5_light.39848a03.png' alt='' />
                </div>
            </div>
            <div className='flex justify-center items-center mx-auto py-10 px-16 bg-white w-full lg:w-2/6'>
                <div className='w-full max-w-md lg:max-w-sm'>
                    <h2 className='text-3xl text-gray-600'>Welcome to<br />InteGreat for Rackbeat!</h2>

                    <div className='mt-3 relative'>
                        <button onClick={showDropdown} className='flex items-center gap-3'>{langFlag} <BsChevronDown /></button>

                        <ul style={{ display: cssDisplay }} className='bg-white shadow-lg rounded-md p-3 pb-1 w-fit absolute left-8'>
                            {
                                languageOptions.map((lang) => (
                                    <li id={lang.id} key={lang.id} disabled className='flex z-50 gap-2 mb-2'>
                                        <img onClick={selectListItem} src={lang.flagimg} height="30" width="30" alt="flagpic" id={lang.id} />
                                        {lang.name}
                                    </li>
                                ))
                            }
                        </ul>
                    </div>

                    <p className='mt-4 mb-2 text-lg'>Please sign-up</p>

                    <fieldset className="border border-solid border-black p-3">
                        <legend className='px-4'>Company Info</legend>

                        <div className='flex flex-col gap-4 mx-4 mt-1 mb-4'>
                            <div>
                                <input onChange={changeCvr} type="text" placeholder="cvr" className="input border-slate-300 w-full rounded-md focus:outline-0 focus:border-gray-600" />
                            </div>

                            <div>
                                <input value={company} type="text" placeholder="company name" className="input border-slate-300 w-full rounded-md focus:outline-0 focus:border-gray-600" />
                            </div>

                            <div>
                                <textarea value={companyAddress} type="text" placeholder="company address" className="textarea text-base border-slate-300 w-full rounded-md focus:outline-0 focus:border-gray-600" />
                            </div>

                            <div className='flex gap-4'>
                                <input value={zip} type="text" placeholder="zip" className="input border-slate-300 rounded-md focus:outline-0 focus:border-gray-600 w-2/6" />
                                <input value={city} type="text" placeholder="city" className="input border-slate-300 rounded-md focus:outline-0 focus:border-gray-600 w-4/6" />
                            </div>
                        </div>
                    </fieldset>

                    <form onSubmit={handleRegistration}>
                        <fieldset className="border border-solid border-black p-3 mt-5">
                            <legend className='px-4'>Personal Info</legend>

                            <div className='flex flex-col gap-4 mx-4 mt-1 mb-4'>
                                <div>
                                    <p className='text-sm mb-1'>Name</p>
                                    <input name='name' required type="text" placeholder="johndoe" className="input border-slate-300 w-full rounded-md focus:outline-0 focus:border-gray-600" />
                                </div>

                                <div>
                                    <p className='text-sm mb-1'>Email</p>
                                    <input name='email' required type="email" placeholder="john@example.com" className="input border-slate-300 w-full rounded-md focus:outline-0 focus:border-gray-600" />
                                </div>

                                <div>
                                    <p className='text-sm mb-1'>Telephone</p>
                                    <input name='phone' required type="text" placeholder="" className="input border-slate-300 w-full rounded-md focus:outline-0 focus:border-gray-600" />
                                </div>
                            </div>
                        </fieldset>

                        <button type='sumbit' className='mt-5 btn bg-gray-600 text-white hover:bg-gray-700 w-full'>Register <AiOutlineArrowRight /></button>
                    </form>

                    <p className='text-xl font-medium text-slate-600 mt-6 text-center'>Already have an account? <Link to='/login' className='text-gray-600 hover:text-gray-700 hover:underline transition-all'>Sign in instead</Link></p>
                </div>
            </div>
        </div>
    );
};

export default RegistrationPage;